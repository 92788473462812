import React, { useState, useRef } from 'react';

function CustomTooltip({ children, tooltipText }) {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);
  const targetRef = useRef(null);

  const handleMouseOver = () => {
    setShowTooltip(true);
  };

  const handleMouseOut = () => {
    setShowTooltip(false);
  };

  return (
    <div ref={targetRef} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
      {children}
      <div ref={tooltipRef} className="tooltip" style={{ display: showTooltip ? 'block' : 'none' }}>
        <p>{tooltipText}</p>
      </div>
    </div>
  );
}

export default CustomTooltip;