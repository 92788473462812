import React, { useState } from 'react';
import Modal from 'react-modal';

const Popup = ({ children, isOpen, closeModal }) => {

  //const [isModalOpen, setIsModalOpen] = useState(true);
  //const handleOpenModal = () => setIsModalOpen(true);
  //const handleCloseModal = () => setIsModalOpen(false);

  if (!isOpen) return null;

  return (
    <div >
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.75)',   

            zIndex: 1000,
          },
          content: {
            position: 'absolute',
            top: '50%',   

            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '60%', // Adjust width as needed
            height: '60%',
            padding: '20px',
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            borderRadius: '5px',
            //textAlign: 'center',
            //display: 'flex', // Add display: flex
            //justifyContent: 'center', // Center content horizontally
            //margin: 0 
          },
        }}
      >
          { children }
      
        
      </Modal>
    </div>
  );
};

export default Popup;